<template>
  <div class="page_body">
    <div :class="is_apply ? 'home_body is_apply_body' : 'home_body'">
      <img class="home_top_logo" src="../../assets/image/logo_img01.png" />
      <div class="home_item_box" v-if="!is_apply">
        <div class="home_content_title">选择证件类型</div>
        <div class="home_popup_box" @click="openPopup()">
          <div
            :class="[
              userData.pass_id == 0 ? 'home_popup no_value_item' : 'home_popup',
            ]"
          >
            {{ userData.pass_name }}
          </div>
          <img src="../../assets/image/popup_icon01.png" class="popup_icon" />
        </div>
        <div class="home_btn" @click="confirmBtnFn(event.collect_type)">
          确认
        </div>
      </div>
      <div class="home_content_box" v-if="auditStatus == 1">
        <div class="home_content_title" style="margin-bottom: 0.3rem">
          {{ matchTitle }}
        </div>
        <div class="home_content_title" style="margin-bottom: 1.32rem">
          {{
            applyInfo.audit_content == ""
              ? "报名申请已提交"
              : applyInfo.audit_content
          }}
        </div>
        <img
          src="../../assets/image/home_content_img01.png"
          class="home_content_img"
        />
        <div class="home_content_tips">审核中</div>
      </div>

      <div class="home_content_box" v-if="auditStatus == 2">
        <div class="home_content_title" style="margin-bottom: 0.3rem">
          {{ matchTitle }}
        </div>
        <img
          v-if="applyInfo.photo != null && applyInfo.photo != ''"
          :src="applyInfo.photo"
          class="home_content_photo"
        />
        <img
          v-else
          src="../../assets/image/upload_img_bg03.png"
          class="home_content_photo"
        />

        <div class="home_content_item_box">
          <img
            src="../../assets/image/name_icon01.png"
            style="width: 0.32rem; margin-right: 0.29rem"
          />
          <div class="home_content_item_name">{{ applyInfo.name }}</div>
        </div>

        <div
          class="home_content_item_box item_val_box"
          v-if="applyInfo.sex != null && applyInfo.sex != ''"
        >
          <div class="img_box">
            <img
              src="../../assets/image/sex_icon01.png"
              style="width: 0.35rem; margin-right: 0.27rem"
            />
          </div>
          <div class="home_content_item_val">{{ applyInfo.sex }}</div>
        </div>

        <div
          class="home_content_item_box item_val_box"
          v-if="
            applyInfo.identity_number != null && applyInfo.identity_number != ''
          "
        >
          <div class="img_box">
            <img
              src="../../assets/image/identity_icon01.png"
              style="width: 0.35rem; margin-right: 0.27rem"
            />
          </div>
          <div class="home_content_item_val">
            {{ applyInfo.identity_number }}
          </div>
        </div>

        <div class="home_content_item_box item_val_box">
          <div class="img_box">
            <img
              src="../../assets/image/company_icon01.png"
              style="width: 0.35rem; margin-right: 0.27rem"
            />
          </div>
          <div class="home_content_item_val">{{ applyInfo.company }}</div>
        </div>

        <div
          class="home_content_item_box item_val_box"
          style="margin-bottom: 0"
          v-if="applyInfo.position != null && applyInfo.position != ''"
        >
          <div class="img_box">
            <img
              src="../../assets/image/code_icon01.png"
              style="width: 0.35rem; margin-right: 0.27rem"
            />
          </div>
          <div class="home_content_item_val">{{ applyInfo.position }}</div>
        </div>
      </div>

      <div class="home_content_box" v-if="auditStatus == 3">
        <div class="home_content_title" style="margin-bottom: 0.3rem">
          {{ matchTitle }}
        </div>
        <div class="home_content_title" style="margin-bottom: 1.32rem">
          请重新提交报名申请
        </div>
        <img
          src="../../assets/image/home_content_img02.png"
          class="home_content_img"
        />
        <div class="home_content_tips no_pass">审核未通过</div>
      </div>

      <div
        class="home_btn"
        style="margin-top: 0.4rem"
        v-if="auditStatus == 3"
        @click="replayBtnFn()"
      >
        重新提交
      </div>
      <div class="area_box" v-if="auditStatus == 2">
        <div class="area_box_title">可活动区域</div>
        <div class="area_list">
          <div
            class="area_list_item"
            v-for="areaItem in applyInfo.area_names"
            :key="areaItem.index"
          >
            {{ areaItem }}
          </div>
        </div>
      </div>
    </div>

    <!-- popup选择器 -->
    <mt-popup
      v-model="popupVisible"
      position="bottom"
      @touchmove.native.stop.prevent
    >
      <div class="popup_body">
        <div class="popup_body_top">
          <div @click="popupCancelFn()" class="popup_body_top_cancel">取消</div>
          <div @click="popupConfirmFn()" class="popup_body_top_confirm">
            确认
          </div>
        </div>
        <mt-picker
          style="width: 100%"
          ref="popupPicker"
          :slots="pickerSlots"
          :visibleItemCount="visibleItemCount"
          :valueKey="valueKey"
          @change="onPickerChange"
        ></mt-picker>
      </div>
    </mt-popup>
  </div>
</template>
<style src="../../assets/css/home/home.css" scoped></style>

<script>
import { Toast } from "mint-ui";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      mark: "",
      company: "",
      pass: "",
      is_apply: true, // 是否提交
      matchTitle: "", // 赛事名称
      auditStatus: 0, // 审核状态
      // popup选择相关
      pickerSlots: [
        {
          flex: 1,
          values: [],
          textAlign: "center",
        },
      ], // popup中Slots
      visibleItemCount: 5, // picker备选数
      popupVisible: false, // popup显示
      popupKey: "", // 当前选择器内容
      popupOpenKey: true,
      valueKey: "pass_name", // 当 values 为对象数组时，作为文本显示在 Picker 中的对应字段的字段名
      userData: {
        pass_id: 0,
        pass_name: "请选择",
      }, // 报名提交
      popupChangeVal: {},
      applyInfo: {
        photo: "",
        name: "",
        company: "",
        position: "",
        audit_content: "",
        sex: "",
        identity_number: "",
        area_names: [],
      }, // 提交数据
      event: {
        collect_type: "",
      },
      is_pass: 2, // 锁定通行证
      is_company: 2, // 锁定公司
      company_name: "", // 锁定公司名称
    };
  },
  created: function () {
    let query = this.$route.query;
    if (query.mark != "" && query.mark != null && query.mark != undefined) {
      this.$store.commit("set_mark", query.mark);
    }

    if (
      query.company != "" &&
      query.company != null &&
      query.company != undefined
    ) {
      this.$store.commit("set_company", query.company);
    }

    if (query.pass != "" && query.pass != null && query.pass != undefined) {
      this.$store.commit("set_pass", query.pass);
    }

    this.mark = this.$store.state.mark;
    this.company = this.$store.state.company;
    this.pass = this.$store.state.pass;
    this.getUserStatus();
  },
  methods: {
    // 获取用户状态
    getUserStatus: function () {
      let that = this;
      let url = "/mobile/getEventInfo";
      let data = {
        mark: that.mark,
        mark_one: that.company,
        mark_two: that.pass,
      };
      that.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.$store.commit("set_isApply", response.is_apply);
            console.log(response.event);
            that.event.collect_type = response.event.collect_type;
            console.log(that.event.collect_type, response.event.collect_type);
            if (response.is_apply != 2) {
              that.$store.commit("set_auditStatus", response.apply_info.status);
              that.auditStatus = response.apply_info.status;
              that.applyInfo.photo = response.apply_info.photo;
              that.applyInfo.name = response.apply_info.name;
              that.applyInfo.company = response.apply_info.company;
              that.applyInfo.position = response.apply_info.position;
              that.applyInfo.audit_content = response.apply_info.audit_content;
              that.applyInfo.area_names = response.apply_info.area_names;
              that.applyInfo.identity_number =
                response.apply_info.identity_number;
              that.applyInfo.sex = response.apply_info.sex;
              that.is_apply = true;
            } else {
              that.$store.commit("set_auditStatus", 0);
              that.auditStatus = 0;
              that.is_apply = false;
            }
            that.$store.commit("set_matchTitle", response.event.event_name);
            that.$store.commit("set_matchId", response.event.event_id);
            that.matchTitle = response.event.event_name;
            that.pickerSlots[0].values = response.pass;
            let obj = {
              id: 0,
              pass_name: "请选择",
            };
            that.pickerSlots[0].values.unshift(obj);

            that.is_pass = response.event.is_pass;
            that.is_company = response.event.is_company;

            if (response.event.is_pass == 1) {
              let selectedName = "";
              response.pass.forEach((i) => {
                if (i.id == response.event.pass_id) {
                  selectedName = i.pass_name;
                }
              });
              // 通行证类型锁定
              that.userData = {
                pass_id: response.event.pass_id,
                pass_name: selectedName,
              };
              that.popupOpenKey = false;
            } else {
              that.popupOpenKey = true;
            }

            if (response.event.is_company == 1) {
              // 锁定公司
              that.company_name = response.event.company_name;
              that.$store.commit("set_companyName", that.company_name);
            } else {
              that.company_name = "";
            }
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 打开popup选择
    openPopup: function () {
      let obj = {};
      if (this.popupOpenKey) {
        this.pickerSlots[0].values.forEach((element) => {
          if (element.id == this.userData.pass_id) {
            obj = element;
          }
        });
        this.$refs.popupPicker.setSlotValue(0, obj);
        this.popupVisible = true;
      }
    },

    // picker选择
    onPickerChange: function (picker, values) {
      if (values[0]) {
        if (values[0] == "") {
          this.popupChangeVal = {
            id: 0,
            pass_name: "请选择",
          };
        } else {
          this.popupChangeVal = values[0];
        }
      }
    },

    // popup弹窗取消
    popupCancelFn: function () {
      this.popupChangeVal = {
        id: this.userData.pass_id,
        pass_name: this.userData.pass_name,
      };

      this.popupVisible = false;
    },

    // popup弹窗确认
    popupConfirmFn: function () {
      this.userData.pass_id = this.popupChangeVal.id;
      this.userData.pass_name = this.popupChangeVal.pass_name;
      this.popupVisible = false;
    },
    // 选择证件确认
    confirmBtnFn: function () {
      if (this.userData.pass_id == 0) {
        Toast({
          message: "请先选择通行证",
        });
        return;
      }
      let queryData = {
        pass: this.userData.pass_id,
        collect_type: this.event.collect_type,
      };
      if (this.is_company == 1) {
        // 锁定公司
        queryData.company = 1;
      }
      this.$router.push({
        name: "ImproveForm",
        query: queryData,
      });
    },
    // 重新提交
    replayBtnFn: function () {
      this.auditStatus = 0;
      this.is_apply = false;
    },
  },
};
</script>
