<template>
  <div class="page_body">
    <div class="login_body">
      <img class="login_top_logo" src="../../assets/image/logo_img01.png" />
      <div class="login_content">
        <div class="login_content_title">上海跳水赛报名</div>
        <div class="login_content_item">
          <div class="login_content_icon_box">
            <img
              class="login_content_icon"
              src="../../assets/image/tel_icon01.png"
              style="width: 0.38rem; margin-left: 0.08rem"
            />
          </div>

          <input
            class="login_content_input"
            type="number"
            v-model="phone"
            placeholder="请输入手机号"
          />
        </div>
        <div class="login_content_item">
          <div class="login_content_icon_box">
            <img
              class="login_content_icon"
              src="../../assets/image/code_icon01.png"
              style="width: 0.35rem; margin-left: 0.1rem"
            />
          </div>
          <input
            class="login_content_input"
            type="number"
            v-model="code"
            placeholder="请输入验证码"
            style="width: 2.4rem; margin-right: 0.3rem"
          />
          <div class="login_get_code" @click="getCodeFn()">
            {{ getCodeTime }}
          </div>
        </div>
      </div>
      <div class="login_btn" @click="loginBtnFn()">登录</div>
      <div class="login_bottom">
        <div
          :class="
            agreeKey
              ? 'login_bottom_icon_box login_agree_item'
              : 'login_bottom_icon_box'
          "
          @click="agreeCheckFn()"
        >
          <img
            v-if="agreeKey"
            class="login_bottom_icon"
            src="../../assets/image/checked_icon01.png"
          />
        </div>
        <div class="login_bottom_wrods_h">我已阅读并同意</div>
        <div class="login_bottom_wrods_l">《用户协议》</div>
        <div class="login_bottom_wrods_h">与</div>
        <div class="login_bottom_wrods_l">《隐私政策》</div>
      </div>
    </div>
  </div>
</template>
<style src="../../assets/css/home/login.css" scoped></style>

<script>
import { Toast } from "mint-ui";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      agreeKey: false,
      phone: "", // 手机号
      phoneShow: "", // 手机号显示
      code: "", // 校验码
      getCodeTime: "获取验证码", // 验证码时间
      getCodeKey: true, // 获取验证码控制
      codeInterval: [],
      mark: "",
    };
  },
  created: function () {
    let query = this.$route.query;
    if (query.mark != "" && query.mark != null && query.mark != undefined) {
      this.$store.commit("set_mark", query.mark);
    }

    if (
      query.company != "" &&
      query.company != null &&
      query.company != undefined
    ) {
      this.$store.commit("set_company", query.company);
    }

    if (query.pass != "" && query.pass != null && query.pass != undefined) {
      this.$store.commit("set_pass", query.pass);
    }

    this.mark = this.$store.state.mark;
  },
  methods: {
    // 设置重新获取验证码时间
    setCodeTimeFn: function () {
      let that = this;
      let time = 30;
      this.codeInterval = setInterval(function () {
        if (time > 0) {
          that.getCodeTime = "重新发送(" + time + ")";
          time = time - 1;
        } else {
          that.getCodeTime = "获取验证码";
          that.getCodeKey = true;
          clearInterval(that.codeInterval);
        }
      }, 1000);
    },
    // 获取验证码
    getCodeFn: function () {
      let that = this;
      if (this.phone == "") {
        Toast({
          message: "手机号不能为空",
        });
        return;
      } else {
        let telText =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-9])\d{8}$/;
        if (!telText.test(this.phone)) {
          Toast({
            message: "手机号格式不正确",
          });
          return false;
        } else {
          if (this.getCodeKey) {
            let url = "/mobile/getPhoneCode";
            let data = {
              phone: that.phone,
            };
            that.getCodeKey = false;
            that.setCodeTimeFn();
            that.axios
              .post(url, data)
              .then((response) => {
                if (response.code != 0) {
                  that.getCodeKey = true;
                  clearInterval(that.codeInterval);
                  that.getCodeTime == "获取验证码";
                  Toast({
                    message: response.msg,
                    duration: 3000,
                  });
                }
              })
              .catch(function (error) {
                that.getCodeKey = true;
                that.getCodeTime = "获取验证码";
                clearInterval(that.codeInterval);
                console.log(error);
              });
          }
        }
      }
    },
    // 勾选
    agreeCheckFn: function () {
      this.agreeKey = !this.agreeKey;
    },
    // 登录
    loginBtnFn: function () {
      let that = this;
      let url = "/mobile/checkPhoneCode";
      let data = {
        phone: that.phone,
        phone_code: that.code,
      };
      if (this.phone == null || this.phone == "" || this.phone == undefined) {
        Toast({
          message: "手机号不能为空",
        });
        return;
      }
      if (this.code == null || this.code == "" || this.code == undefined) {
        Toast({
          message: "校验码不能为空",
        });
        return;
      }
      if (this.agreeKey == false) {
        Toast({
          message: "请先勾选同意协议",
        });
        return;
      }
      that.axios
        .post(url, data)
        .then((response) => {
          if (response.code == 0) {
            that.$store.commit("set_loginToken", response.token);
            that.$router.replace({
              name: "Home",
            });
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
