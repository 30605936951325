<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created: function () {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
