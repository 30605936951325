import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../views/home/login.vue";
import Home from "../views/home/home.vue";

import ImproveForm from "../views/home/improveForm.vue";

Vue.use(VueRouter);

const routes = [
  // home
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "久事赛事",
    },
  }, // 登录
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
    },
  }, // 登录
  /*************************************************************************** */
  /*************************************************************************** */
  /*************************************************************************** */
  /*************************************************************************** */
  {
    path: "/improveForm",
    name: "ImproveForm",
    component: ImproveForm,
    meta: {
      title: "完善信息",
    },
  }, // 完善信息
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 需要登录的页面
  const nextRoute = ["Home", "ImproveForm"];
  let isLogin = sessionStorage.getItem("JIUSHI_LOGIN_TOKEN"); // 判断是否登录，本地存储有用户数据则视为已经登录

  // 未登录状态；当路由到 nextRoute 指定页时，跳转至 登录
  if (nextRoute.indexOf(to.name) >= 0) {
    // 检测是否登录的页面
    if (!isLogin) {
      // 如果未登录（本地存储无用户数据），并且要跳到登录页面
      if (from.name === "Login") {
        next("/login");
        return;
      }

      router.push({
        name: "Login",
        params: {
          redirect: to.fullPath,
        },
      });
    }
  }

  // 已登录状态；当路由到 登录 时，跳转至 首页
  if (to.name === "Login") {
    if (isLogin) {
      next("/");
      return;
    }
  }
  next(); // 必须使用 next ,执行效果依赖 next 方法的调用参数
});

export default router;
