import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "./request/http.js";
import store from "./store";
import vueAxios from "vue-axios";
import "./assets/js/rem.js";

// 公共样式
import "./assets/css/index.css";
import "./assets/css/normalize.css";

// 移动端ui, 按需引入
import "mint-ui/lib/style.css";
import {
  Swipe,
  SwipeItem,
  InfiniteScroll,
  DatetimePicker,
  Popup,
  Picker,
  Navbar,
  TabItem,
  TabContainer,
  TabContainerItem,
} from "mint-ui";

Vue.component(DatetimePicker.name, DatetimePicker);
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
Vue.use(InfiniteScroll);
Vue.component(Popup.name, Popup);
Vue.component(Picker.name, Picker);
Vue.component(Navbar.name, Navbar);
Vue.component(TabItem.name, TabItem);
Vue.component(TabContainer.name, TabContainer);
Vue.component(TabContainerItem.name, TabContainerItem);

Vue.use(vueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  components: {
    App,
  },
  render: (h) => h(App),
}).$mount("#app");
