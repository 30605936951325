import Axios from "axios";
// import store from "../store";
import vue from "vue";

// Axios.defaults.baseURL = "/coach/api";
Axios.defaults.baseURL = "/api";
// request请求拦截器
Axios.defaults.withCredentials = true;
vue.prototype.$axios = Axios;
//请求超时时间
Axios.defaults.timeout = 100000;
Axios.defaults.headers.get["Content-Type"] = "application/json";
Axios.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.getItem("JIUSHI_LOGIN_TOKEN"));
    if (config.url.indexOf("/mobile/login") == -1) {
      if (token) {
        config.headers.Authorization = `${token}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone返回拦截器
Axios.interceptors.response.use(
  (response) => {
    if (
      response.data.code == -1 ||
      response.data.code == -2 ||
      response.data.code == -3
    ) {
      sessionStorage.removeItem("JIUSHI_LOGIN_TOKEN");
      location.replace("/coach/login");
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
