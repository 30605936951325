import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseUrl: "https://jussevent.1meeting.com/", // 默认前端地址
    baseAPIUrl: "https://jussevent.1meeting.com/api/", // 默认后端地址
    // baseUrl: "http://118.31.63.29:85/", // 默认前端地址
    // baseAPIUrl: "http://118.31.63.29:85/api", // 默认后端地址
    // 基本信息
    loginToken: JSON.parse(sessionStorage.getItem("JIUSHI_LOGIN_TOKEN")) || "", // 登录验证token
    mark: JSON.parse(sessionStorage.getItem("JIUSHI_MARK")) || "", // 部门mark
    company: JSON.parse(sessionStorage.getItem("JIUSHI_COMPANYMARK")) || "", // 公司mark
    companyName: JSON.parse(sessionStorage.getItem("JIUSHI_COMPANYNAME")) || "", // 公司名称
    pass: JSON.parse(sessionStorage.getItem("JIUSHI_PASSMARK")) || "", // 通行证mark
    isApply: JSON.parse(sessionStorage.getItem("JIUSHI_ISAPPLY")) || false, // 登录用户是否提交过
    auditStatus:
      JSON.parse(sessionStorage.getItem("JIUSHI_AUDITSTATUS")) || false, // 登录用户是否完通过审核;0: 未提交;1:审核中 2：审核完成 3：待重新提交审核
    // 页面缓存信息
    matchTitle: JSON.parse(sessionStorage.getItem("JIUSHI_MATCHTITLE")) || "", // 赛事名称
    matchId: JSON.parse(sessionStorage.getItem("JIUSHI_MATCHID")) || "", // 赛事id
  },
  mutations: {
    // 更新token
    set_loginToken(state, value) {
      state.loginToken = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_LOGIN_TOKEN", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_LOGIN_TOKEN");
      }
    },
    // 更新部门mark
    set_mark(state, value) {
      state.mark = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_MARK", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_MARK");
      }
    },
    // 更新公司mark
    set_company(state, value) {
      state.company = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_COMPANYMARK", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_COMPANYMARK");
      }
    },
    // 更新公司名称
    set_companyName(state, value) {
      state.companyName = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_COMPANYNAME", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_COMPANYNAME");
      }
    },
    // 更新通行证mark
    set_pass(state, value) {
      state.pass = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_PASSMARK", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_PASSMARK");
      }
    },
    // 更新登录用户是否提交过
    set_isApply(state, value) {
      if (value == 1) {
        state.isApply = true;
        sessionStorage.setItem("JIUSHI_ISAPPLY", JSON.stringify(true));
      } else {
        state.isApply = false;
        sessionStorage.setItem("JIUSHI_ISAPPLY", JSON.stringify(false));
      }
    },
    // 更新登录用户是否完通过审核
    set_auditStatus(state, value) {
      state.match = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_AUDITSTATUS", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_AUDITSTATUS");
      }
    },
    // 更新赛事名称
    set_matchTitle(state, value) {
      state.matchTitle = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_MATCHTITLE", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_MATCHTITLE");
      }
    },
    // 更新赛事id
    set_matchId(state, value) {
      state.matchId = value;
      if (value != "") {
        sessionStorage.setItem("JIUSHI_MATCHID", JSON.stringify(value));
      } else {
        sessionStorage.removeItem("JIUSHI_MATCHID");
      }
    },
  },
  actions: {},
  modules: {},
});
