<template>
  <div class="page_body">
    <div class="improve_form_body">
      <div class="improve_form_item_box">
        <div class="improve_form_content_title">{{ matchTitle }}</div>
        <div class="improve_form_content_item img_box">
          <div class="img_box_item">
            <div class="improve_form_content_icon_box">
              <img
                class="improve_form_content_icon"
                src="../../assets/image/photo_icon01.png"
                style="width: 0.34rem; margin-left: 0.08rem"
              />
            </div>
            <div class="has_img_words">照片</div>
          </div>

          <div class="img_box_item">
            <div class="img_box_viewBox">
              <img
                v-if="submitData.photo != '' && submitData.photo != null"
                :src="submitData.photo"
                class="img_box_view"
              />
              <img
                v-else
                class="img_box_view"
                src="../../assets/image/upload_img_bg03.png"
              />
              <input
                type="file"
                accept="image/*"
                ref="uploadImg"
                id="upload_img_btn"
                @change="uploadImgBtn($event)"
              />
            </div>

            <img class="img_box_icon" src="../../assets/image/to_icon05.png" />
          </div>
        </div>

        <div class="improve_form_content_item">
          <div class="improve_form_content_icon_box">
            <img
              class="improve_form_content_icon"
              src="../../assets/image/name_icon01.png"
              style="width: 0.32rem; margin-left: 0.1rem"
            />
          </div>
          <input
            class="improve_form_content_input"
            type="text"
            maxlength="10"
            v-model="submitData.name"
            placeholder="姓名"
          />
        </div>

        <div class="improve_form_content_item">
          <div class="improve_form_content_icon_box">
            <img
              class="improve_form_content_icon"
              src="../../assets/image/sex_icon01.png"
              style="width: 0.35rem; margin-left: 0.1rem"
            />
          </div>

          <div
            :class="
              submitData.sex == '请选择'
                ? 'improve_form_content_input no_val'
                : 'improve_form_content_input'
            "
            @click="openPopup('sex')"
          >
            {{ submitData.sex == "请选择" ? "性别" : submitData.sex }}
          </div>
        </div>

        <div
          class="improve_form_content_item"
          v-if="this.$route.query.collect_type == '1'"
        >
          <div class="improve_form_content_icon_box">
            <img
              class="improve_form_content_icon"
              src="../../assets/image/identity_icon01.png"
              style="width: 0.35rem; margin-left: 0.1rem"
            />
          </div>
          <input
            class="improve_form_content_input"
            type="text"
            maxlength="30"
            v-model="submitData.identity_number"
            placeholder="证件号"
          />
        </div>

        <div
          class="improve_form_content_item"
        >
          <div class="improve_form_content_icon_box">
            <img
              class="improve_form_content_icon"
              src="../../assets/image/company_icon01.png"
              style="width: 0.35rem; margin-left: 0.1rem"
            />
          </div>
          <input
            class="improve_form_content_input"
            type="text"
            maxlength="20"
            v-model="submitData.company"
            placeholder="公司"
            :disabled="!inputKey"
          />
        </div>

        <div
          class="improve_form_content_item"
          v-if="this.$route.query.collect_type == '1'"
        >
          <div class="improve_form_content_icon_box">
            <img
              class="improve_form_content_icon"
              src="../../assets/image/code_icon01.png"
              style="width: 0.35rem; margin-left: 0.1rem"
            />
          </div>
          <input
            class="improve_form_content_input"
            type="text"
            maxlength="10"
            v-model="submitData.position"
            placeholder="工作岗位"
          />
        </div>
        <div
          class="improve_form_content_item"
          v-if="this.$route.query.collect_type == '1'"
        >
          <div class="improve_form_content_icon_box">
            <img
              class="improve_form_content_icon"
              src="../../assets/image/company_icon01.png"
              style="width: 0.35rem; margin-left: 0.1rem"
            />
          </div>
          <input
            class="improve_form_content_input"
            type="text"
            maxlength="20"
            v-model="submitData.address"
            placeholder="现居住地"
            :disabled="!inputKey"
          />
        </div>
        <div class="improve_form_btn" @click="confirmBtnFn()">确认</div>
      </div>
    </div>
    <!-- popup选择器 -->
    <mt-popup
      v-model="popupVisible"
      position="bottom"
      @touchmove.native.stop.prevent
    >
      <div class="popup_body">
        <div class="popup_body_top">
          <div @click="popupCancelFn()" class="popup_body_top_cancel">取消</div>
          <div @click="popupConfirmFn()" class="popup_body_top_confirm">
            确认
          </div>
        </div>
        <mt-picker
          style="width: 100%"
          ref="popupPicker"
          :slots="pickerSlots"
          :visibleItemCount="visibleItemCount"
          :valueKey="valueKey"
          @change="onPickerChange"
        ></mt-picker>
      </div>
    </mt-popup>
  </div>
</template>
<style src="../../assets/css/home/improveForm.css" scoped></style>

<script>
import { Indicator, Toast, MessageBox } from "mint-ui";

export default {
  name: "ImproveForm",
  components: {},
  data() {
    return {
      mark: "",
      passId: "", // 通行证id
      matchTitle: "", // 赛事名称
      matchId: "", // 赛事id
      submitData: {
        name: "", // 姓名
        photo: "", // 照片
        local_image: "", // 照片服务器路径
        sex: "请选择", // 性别
        company: "", // 公司
        identity_number: "", //证件号
        position: "", // 职位
        address: "", //现居住地
      },
      // popup选择相关
      pickerSlots: [], // popup中Slots
      visibleItemCount: 3, // picker备选数
      popupVisible: false, // popup显示
      popupKey: "", // 当前选择器内容
      valueKey: "", // 当 values 为对象数组时，作为文本显示在 Picker 中的对应字段的字段名
      sexSlots: [
        {
          flex: 1,
          values: ["请选择", "男", "女"],
          textAlign: "center",
        },
      ], // 性别slots
      sexChange: "请选择", // 性别选择
      is_company: 2, // 锁定公司
      inputKey: true,
    };
  },
  created: function () {
    console.log(this.$route.query.collect_type);
    let query = this.$route.query;
    if (query.pass != "" && query.pass != null && query.pass != undefined) {
      this.passId = query.pass;
    }

    if (
      query.company != "" &&
      query.company != null &&
      query.company != undefined
    ) {
      this.is_company = query.company;
    }

    if (this.is_company == 1) {
      this.submitData.company = this.$store.state.companyName;
      this.inputKey = false;
    } else {
      this.submitData.company = "";
      this.inputKey = true;
    }

    this.mark = this.$store.state.mark;
    this.matchTitle = this.$store.state.matchTitle;
    this.matchId = this.$store.state.matchId;
  },
  methods: {
    // 上传头像图片
    uploadImgBtn: function (e) {
      let that = this;
      let file = e.target.files[0];
      if (file.size > 1024 * 1024 * 3) {
        Toast({
          message: "照片大小超过3MB",
          duration: 3000,
        });
        that.$refs.uploadImg.value = "";
      } else {
        if (file != undefined && file != null) {
          let reader = new FileReader();
          reader.onload = (res) => {
            that.uploadImgFn(res.target.result);
          };
          reader.readAsDataURL(file);
        }
      }
    },

    // 上传图片到服务器
    uploadImgFn: function (imgData) {
      let that = this;
      let url = "/upload/uploadPhoto";
      let data = {
        event_id: that.matchId,
        file: imgData,
      };
      Indicator.open("上传中...");
      this.axios
        .post(url, JSON.stringify(data))
        .then((response) => {
          Indicator.close();
          if (response.code == 0) {
            if (response.is_face == 1) {
              let path = response.image;
              that.submitData.photo = path;
              that.submitData.local_photo = response.local_image;
            } else {
              Toast({
                message: "请上传人像图片",
                duration: 3000,
              });
            }
          } else {
            Toast({
              message: response.msg,
              duration: 3000,
            });
          }
        })
        .catch(function (error) {
          Indicator.close();
          console.log(error);
        });
    },
    // 提交
    confirmBtnFn: function () {
      let that = this;
      let url = "/mobile/eventEnroll";
      let data = {
        name: this.submitData.name, // 姓名
        photo: this.submitData.photo, // 照片
        local_photo: this.submitData.local_photo, // 照片
        company: this.submitData.company, // 公司
        position: this.submitData.position, // 职位
        pass_id: this.passId,
        mark: this.mark,
        address: this.submitData.address, //现居住地
        sex: this.submitData.sex == "请选择" ? "" : this.submitData.sex,
        identity_number: this.submitData.identity_number,
      };
      if (data.photo == null || data.photo == "" || data.photo == undefined) {
        Toast({
          message: "请先上传照片",
        });
        return;
      }
      if (data.name == null || data.name == "" || data.name == undefined) {
        Toast({
          message: "请填写姓名",
        });
        return;
      }
      if (this.$route.query.collect_type == "1") {
        if (
          data.company == null ||
          data.company == "" ||
          data.company == undefined
        ) {
          Toast({
            message: "请填写公司",
          });
          return;
        }
        if (
          data.position == null ||
          data.position == "" ||
          data.position == undefined
        ) {
          Toast({
            message: "请填写职位",
          });
          return;
        }
        if (
          data.address == null ||
          data.address == "" ||
          data.address == undefined
        ) {
          Toast({
            message: "请填写居住地",
          });
          return;
        }
      }

      // if (
      //   data.position == null ||
      //   data.position == "" ||
      //   data.position == undefined
      // ) {
      //   Toast({
      //     message: "请填写工作岗位",
      //   });
      //   return;
      // }

      MessageBox.confirm("确认提交?")
        .then((action) => {
          console.log(action);
          that.axios
            .post(url, data)
            .then((response) => {
              if (response.code == 0) {
                Toast({
                  message: "提交成功",
                  duration: 3000,
                });
                that.$router.replace({
                  name: "Home",
                });
              } else {
                Toast({
                  message: response.msg,
                  duration: 3000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 打开popup选择
    openPopup: function (key) {
      this.popupKey = key;
      this.valueKey = "";
      if (key == "sex") {
        this.pickerSlots = this.sexSlots;
        this.$refs.popupPicker.setSlotValue(0, this.submitData.sex);
        this.popupVisible = true;
      }
    },

    // picker选择
    onPickerChange: function (picker, values) {
      if (this.popupKey == "sex") {
        if (values[0]) {
          if (values[0] == "") {
            this.sexChange = "请选择";
          } else {
            this.sexChange = values[0];
          }
        }
      }
    },

    // popup弹窗取消
    popupCancelFn: function () {
      let key = this.popupKey;
      if (key == "sex") {
        this.sexChange = this.submitData.sex;
      }
      this.popupVisible = false;
    },

    // popup弹窗确认
    popupConfirmFn: function () {
      let key = this.popupKey;
      if (key == "sex") {
        this.submitData.sex = this.sexChange;
      }
      this.popupVisible = false;
    },
  },
};
</script>
